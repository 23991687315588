<template>
  <div class="alert_box" ref="alert_box">
    <i v-if="alertType == 'error'" class="fa fa-exclamation-circle" style="font-size: 24px;margin: 0 10px 0 20px"></i>
    <i v-if="alertType == 'success'" class="fa fa-check-circle" style="font-size: 24px;margin: 0 10px 0 20px"></i>
    {{ alertText }}
  </div>
</template>

<script>
export default {
  name: "AlertError",
  data: () => ({
    alertType: undefined,
    alertText: ''
  }),
  methods: {
    showErrorAlerts(message, ...awit) {
      this.alertText = message
      if (!typeof awit === 'number' && !isNaN(awit) || awit <= 0) awit = 3000
      this.alertType = 'error'
      // this.$refs.alert_box.style.background = '#ff5252'
      this.$refs.alert_box.style.color = '#ff5252'
      this.$refs.alert_box.style.background = '#fff'
      this.$refs.alert_box.classList.add('active')
      setTimeout(() => {
        this.$refs.alert_box.classList.remove('active')
      }, awit)
    },
    showSuccessAlerts(message, ...awit) {
      this.alertText = message
      if (!typeof awit === 'number' && !isNaN(awit) || awit <= 0) awit = 3000
      this.alertType = 'success'
      // this.$refs.alert_box.style.background = '#4caf50'
      this.$refs.alert_box.style.color = '#4caf50'
      this.$refs.alert_box.style.background = '#fff'
      this.$refs.alert_box.classList.add('active')
      setTimeout(() => {
        this.$refs.alert_box.classList.remove('active')
      }, awit)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert_box {
  width: 300px;
  min-height: 60px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;

  &.active {
    width: 700px;
    visibility: inherit;
    opacity: 1;
  }
}
</style>

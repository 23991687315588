<template>
  <div class="login_container">
    <div class="login_card" ref="login_card">
      <span class="close" @click="close"><i class="fa fa-close"></i></span>
      <div v-show="!isShowReset">
        <div class="tabs">
          <span :class="{active: selected == 0}" @click="switchLogin">登录</span>
          <span :class="{active: selected == 1}" @click="switchRegister">注册</span>
        </div>
        <!-- 登录 -->
        <div class="tabs_item" v-show="!isShowRegister">
          <el-form class="input_list" ref="loginForm" :model="handleForm" :rules="loginRules">
            <el-form-item prop="mobile">
              <el-input @focus="() => {this.$refs.loginBtn.style.background = '#3E35E7'}"
                        @blur="() => {this.$refs.loginBtn.style.background = '#CCCCCC'}"
                        v-model="handleForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item v-if="!isPhoneLogin" prop="password">
              <el-input @focus="() => {this.$refs.loginBtn.style.background = '#3E35E7'}"
                        @blur="() => {this.$refs.loginBtn.style.background = '#CCCCCC'}"
                        v-model="handleForm.password" :type="loginIcon ? 'text' : 'password'" ref="logpas"
                        placeholder="请输入6-16位密码">
                <span class="eye_toggle_pwd" slot="suffix"
                      @click="loginIcon = !loginIcon">
                <i :class="{'iconfont': true, 'icon-icon-eye-close': !loginIcon, 'icon-icon-eye-open': loginIcon}"></i></span>
              </el-input>
            </el-form-item>
            <el-form-item v-else prop="verifyCode">
              <el-input @focus="() => {this.$refs.loginBtn.style.background = '#3E35E7'}"
                        @blur="() => {this.$refs.loginBtn.style.background = '#CCCCCC'}"
                        v-model="handleForm.verifyCode" placeholder="请输入6位验证码"></el-input>
              <button class="get_code" v-show="sendAuthCode" type="button" @click="getVerifyCode">获取验证码</button>
              <button type="button" v-show="!sendAuthCode" class="get_code">{{ codeTime }}秒</button>
            </el-form-item>
            <div class="tools">
              <span v-if="!isPhoneLogin" @click="switchCodeLogin">手机验证码登录</span>
              <span v-else @click="switchPwdLogin">密码登录</span>
              <span @click="switchUpd">忘记密码</span>
            </div>
          </el-form>
          <div class="btn">
            <button type="button" class="hover_btn" ref="loginBtn" @click="login">登录</button>
          </div>
        </div>
        <!--  注册 -->
        <div class="tabs_item" v-show="isShowRegister">
          <el-form class="input_list" ref="registerForm" :model="handleForm" :rules="registerRules">
            <el-form-item prop="mobile">
              <el-input v-model="handleForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="verifyCode">
              <el-input v-model="handleForm.verifyCode" placeholder="请输入6位验证码"></el-input>
              <button type="button" class="get_code" v-show="sendAuthCode" @click="getVerifyCode">获取验证码</button>
              <button type="button" disabled v-show="!sendAuthCode" class="get_code">{{ codeTime }}秒</button>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="handleForm.password" ref="pas" :type="registerNewIcon ? 'text' : 'password'"
                        placeholder="请输入6-16位密码">
                <span class="eye_toggle_pwd" slot="suffix"
                      @click="registerNewIcon = !registerNewIcon">
                <i :class="{'iconfont': true, 'icon-icon-eye-close': !registerNewIcon, 'icon-icon-eye-open': registerNewIcon}"></i></span>
              </el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input v-model="handleForm.confirmPassword" ref="pass" :type="registerCofIcon ? 'text' : 'password'"
                        placeholder="请再次确认密码">
                <span class="eye_toggle_pwd" slot="suffix"
                      @click="registerCofIcon = !registerCofIcon">
                <i :class="{'iconfont': true, 'icon-icon-eye-close': !registerCofIcon, 'icon-icon-eye-open': registerCofIcon}"></i></span>
              </el-input>
            </el-form-item>
            <el-form-item prop="nickName">
              <el-input v-model="handleForm.nickName" placeholder="请输入昵称"></el-input>
            </el-form-item>
            <el-form-item class="check_box_area" prop="policy">
              <el-checkbox-group v-model="handleForm.policy">
                <el-checkbox label="1" name="policy">我已阅读并接受
                  <span style="color: #3E35E7" @click="skipPrivacy('privacy')">《隐私政策》</span>和
                  <span style="color: #3E35E7" @click="skipPrivacy('server')">《服务协议》</span>
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>

          </el-form>
          <div class="btn">
            <button type="button" class="hover_btn" @click="register">注册</button>
          </div>
        </div>
      </div>
      <template v-if="isShowReset">
        <div class="tabs tabs_reset">
          <span>重置密码</span>
        </div>
        <div class="tabs_item">
          <el-form class="input_list" ref="resetForm" :model="handleForm" :rules="resetRules">
            <el-form-item prop="mobile">
              <el-input v-model="handleForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="verifyCode">
              <el-input v-model="handleForm.verifyCode" placeholder="请输入6位验证码"></el-input>
              <button type="button" class="get_code" v-show="sendAuthCode" @click="getVerifyCode">获取验证码</button>
              <button type="button" v-show="!sendAuthCode" class="get_code">{{ codeTime }}秒</button>
            </el-form-item>
            <el-form-item prop="newPassword">
              <el-input v-model="handleForm.newPassword" ref="nupds" :type="resetNewIcon ? 'text' : 'password'"
                        placeholder="请输入新密码">
              <span class="eye_toggle_pwd" slot="suffix"
                    @click="resetNewIcon = !resetNewIcon">
                <i :class="{'iconfont': true, 'icon-icon-eye-close': !resetNewIcon, 'icon-icon-eye-open': resetNewIcon}"></i></span>
              </el-input>

            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input v-model="handleForm.confirmPassword" ref="updss" :type="resetCofIcon ? 'text' : 'password'"
                        placeholder="请再次确认密码">
              <span class="eye_toggle_pwd" slot="suffix"
                    @click="resetCofIcon = !resetCofIcon">
                <i :class="{'iconfont': true, 'icon-icon-eye-close': !resetCofIcon, 'icon-icon-eye-open': resetCofIcon}"></i></span>
              </el-input>

            </el-form-item>
          </el-form>
          <div class="btn btns" style="margin-top: 30px">
            <button type="button" class="hover_btn" style="margin-right: 50px" @click="updatePassword">确认</button>
            <button type="button" @click="() => {
            this.isShowReset=false;
            this.handleForm.verifyCode = ''
            this.clearTime()
          }">取消
            </button>
          </div>
        </div>
      </template>
    </div>
    <alert-error ref="alert_login"></alert-error>
  </div>
</template>

<script>
import AlertError from "../../components/AlertError/AlertError";
import {setToken} from "../../utils/auth";
import {mapActions} from 'vuex'
import {mapMutations} from "vuex/dist/vuex.mjs";


export default {
  name: 'Popup',
  components: {AlertError},
  props: {
    isShow: {
      Type: Boolean,
      default: false
    },
    UserInfo: {
      Type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      selected: 0,
      isPhoneLogin: true,
      isShowRegister: false,
      isShowReset: false,
      handleForm: {
        loginName: '',
        mobile: '',
        password: '',
        newPassword: '',
        confirmPassword: '',
        verifyCode: null,
        nickName: '',
        policy: []
      },
      loginIcon: false,
      registerNewIcon: false,
      registerCofIcon: false,
      resetNewIcon: false,
      resetCofIcon: false,
      // 验证码框是否显示
      sendAuthCode: true,
      //60秒倒计时
      codeTime: null,
      codeTimeTimer: undefined,
      loginRules: {
        mobile: [
          {required: true, message: "请输入手机号", trigger: ['blur']},
          {pattern: '^(1[0-9])\\d{9}$', message: '请输入正确格式手机号', trigger: ['blur']}
        ],
        verifyCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        password: [{
          required: true,
          pattern: '^[a-zA-Z\\d]{6,16}$',
          message: '密码格式为（6-16位密码，区分大小写）',
          trigger: ['blur'],
        }],
      },
      registerRules: {
        mobile: [
          {required: true, message: "请输入手机号", trigger: ['blur']},
          {pattern: '^(1[0-9])\\d{9}$', message: '请输入正确格式手机号'}
        ],
        verifyCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        password: [{
          required: true,
          pattern: '^[a-zA-Z\\d]{6,16}$',
          message: '密码格式为（6-16位密码，区分大小写）',
          trigger: ['blur'],
        }],
        confirmPassword: [{
          required: true,
          pattern: '^[a-zA-Z\\d]{6,16}$',
          message: '密码格式为（6-16位密码，区分大小写）',
          trigger: ['blur'],
        }],
        nickName: [
          {required: true, message: '请输入昵称', trigger: 'blur'}
        ],
        policy: [
          {type: 'array', required: true, message: '请勾选协议', trigger: 'change'}
        ],
      },

      resetRules: {
        mobile: [
          {required: true, message: "请输入手机号", trigger: ['blur']},
          {pattern: '^(1[0-9])\\d{9}$', message: '请输入正确格式手机号'}
        ],
        verifyCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        newPassword: [{
          required: true,
          pattern: '^[a-zA-Z\\d]{6,16}$',
          message: '密码格式为（6-16位密码，区分大小写）',
          trigger: ['blur'],
        }],
        confirmPassword: [{
          required: true,
          pattern: '^[a-zA-Z\\d]{6,16}$',
          message: '密码格式为（6-16位密码，区分大小写）',
          trigger: ['blur'],
        }],
      },
      defferScroll: function (event) {
        event.preventDefault()
      }
    }
  },
  watch: {
    privacyPolicy(newVal) {
      this.handleForm.checked = newVal && this.handleForm.platformAgreement;
    },
    platformAgreement(newVal) {
      this.handleForm.checked = newVal && this.handleForm.privacyPolicy;
    }
  },
  mounted() {
    // this.NoScroll()
    document.body.addEventListener("touchmove", this.defferScroll, {passive: false});
    document.body.addEventListener("wheel", this.defferScroll, {passive: false});
  },
  destroyed() {
    document.body.removeEventListener("touchmove", this.defferScroll, {passive: false});
    document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
  },
  methods: {
    ...mapMutations({
      setIsLogin: 'setIsLogin',
      setUserInfo: 'setUserInfo'
    }),
    ...mapActions({
      setActionToken: 'setActionToken'
    }),
    skipPrivacy(strCode) {
      // let routeUrl = this.$router.resolve({path: "/about-us/" + strCode});
      // window.open(routeUrl.href, '_blank');
      this.$router.push({path: "/about-us/" + strCode})
      // 关闭模态框
      this.close()
    },
    // 切换至登录
    switchLogin() {
      // 移除注册表单验证
      this.$refs.registerForm.clearValidate()
      this.selected = 0;
      this.isShowRegister = false;
      this.verifyCode = null
      this.clearTime()
    },
    switchRegister() {
      // 移除登录
      this.$refs.loginForm.clearValidate()
      this.selected = 1;
      this.isShowRegister = true;
      this.verifyCode = null
      this.clearTime()
    },
    switchUpd() {
      this.isShowReset = true;
      this.handleForm.verifyCode = ''
      this.clearTime()
    },
    switchCodeLogin() {
      this.isPhoneLogin = true
      this.$refs.loginForm.clearValidate('password')
    },
    switchPwdLogin() {
      this.isPhoneLogin = false
      this.$refs.loginForm.clearValidate('verifyCode')
    },
    // 关闭回调
    close() {
      this.CanScroll()
      this.$emit('update:isShow', false)
    },
    // 获取验证码
    async getVerifyCode() {
      if (this.handleForm.mobile) {
        const res = await this.axios.get("/user/verify-code", {params: {mobile: this.handleForm.mobile}})
        if (res.code === 0) {
          this.sendAuthCode = false;
          this.codeTime = 60;
          this.successAlerts('验证码发送成功，请查收')
          this.codeTimeTimer = setInterval(() => {
            this.codeTime--;
            if (this.codeTime <= 0) {
              this.clearTime()
            }
          }, 1000);
        } else {
          this.errorAlerts(res.msg)
        }
      } else {
        this.$nextTick(() => {
          this.errorAlerts('请输入手机号')
        })
      }
    },
    clearTime() {
      this.sendAuthCode = true;
      clearInterval(this.codeTimeTimer);
    },
    // 登录
    async login() {
      this.$refs.loginForm.validate((valid) => {
        if (!valid) return
        // 账号密码登录
        if (!this.isPhoneLogin) {
          let params = {
            loginName: this.handleForm.mobile,
            password: this.handleForm.password
          }
          this.axios.get('/user/login', {params}).then(res => {
            if (res.code == 0) {
              this.$message.success('登录成功!')

              this.UserInfo.token = res.token
              setToken(res.token)
              this.setActionToken(res.token)
              this.setIsLogin(true)
              // 获取用户信息
              this.axios.get('/user/info').then(resInfo => {
                if (resInfo.code == 0) {
                  this.setUserInfo(resInfo.data)
                }
                this.close()
              })
            } else {
              this.errorAlerts(res.msg)
            }
          })
        } else {
          // 手机验证码登录
          let params = {
            mobile: this.handleForm.mobile,
            verifyCode: this.handleForm.verifyCode
          }
          this.axios.get('/user/login-with-tel', {params}).then(res => {
            if (res.code == 0) {
              this.$message.success('登录成功!')


              this.UserInfo.token = res.token
              setToken(res.token)
              this.setActionToken(res.token)
              this.setIsLogin(true)
              // 获取用户信息
              this.axios.get('/user/info').then(resInfo => {
                if (resInfo.code == 0) {
                  this.setUserInfo(resInfo.data)
                }
                this.close()
              })
            } else {
              this.errorAlerts(res.msg)
            }
          })
        }

      })
    },
    // 注册
    register() {
      let params = {
        mobile: this.handleForm.mobile,
        nickName: this.handleForm.nickName,
        verifyCode: this.handleForm.verifyCode,
        password: this.handleForm.password,
        confirmPassword: this.handleForm.confirmPassword
      }
      this.$refs.registerForm.validate((valid) => {
        if (!valid) return
        this.axios.get('/user/register', {params}).then(res => {
          if (res.code == 0) {
            this.successAlerts(res.msg)
            // 跳转至登录
            this.handleForm.verifyCode = ''
            this.switchLogin()
          } else {
            this.errorAlerts(res.msg)
          }
        })
      })
    },
    // 修改密码
    updatePassword() {
      this.$refs.resetForm.validate(valid => {
        if (!valid) return
        let params = {
          mobile: this.handleForm.mobile,
          verifyCode: this.handleForm.verifyCode,
          newPassword: this.handleForm.newPassword,
          confirmPassword: this.handleForm.confirmPassword
        }
        this.axios.get('/user/reset-pass-by-code', {params}).then(res => {
          if (res.code == 0) {
            this.successAlerts(res.msg)
            this.handleForm.verifyCode = '';
            this.isShowReset = false;
            this.clearTime()
            this.switchLogin()
          } else {
            this.errorAlerts(res.msg)
          }
        })
      })
    },
    errorAlerts(message) {
      this.$refs.alert_login.showErrorAlerts(message)
    },
    successAlerts(message) {
      this.$refs.alert_login.showSuccessAlerts(message)
    },
    NoScroll() {
      let mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.body.style.transition = 'all 0.8s ease-in'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },
    CanScroll() {
      let mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' // 出现滚动条
      document.removeEventListener('touchmove', mo, false)
    },
  }
}
</script>

<style lang='scss' scoped>
.login_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9100;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  animation: move .3s linear;
  animation-fill-mode: forwards;
  @keyframes move {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .hover_btn {
    background: #3e35e7 !important;
  }

  .hover_btn:hover {
    background: #3e35e7 !important;
  }

  .login_card {
    width: 460px;
    box-sizing: border-box;
    min-height: 240px;
    background: #fff;
    padding: 38px 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    opacity: 1;
    transition: all 300ms ease-in-out;


    .close {
      position: absolute;
      top: 10px;
      right: 12px;
      font-size: 1.4rem;
      cursor: pointer;
      color: #BDBEBD;

      &:hover {
        color: #3E35E7;
      }
    }

    .tabs {
      text-align: left;
      padding-bottom: 10px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: calc(100% + 48px);
        height: 1px;
        background: #EFEFEF;
        bottom: 0;
        left: -24px;
      }

      span {
        display: inline-block;
        text-align: center;
        width: 70px;
        font-size: 1.1rem;
        line-height: 1.8rem;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        user-select: none;
        transition: all 0.3s ease-in;
        color: #999999;

        &:first-child {
          margin-right: 20px;
        }

        &:before {
          content: '';
          width: 0;
          height: 3px;
          background: #3E35E7;
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 8px;
          transition: all 0.3s ease-in;
        }

        &:hover {
          color: #333333;

          &:before {
            width: 60%;
          }
        }
      }

      .active {
        color: #333333;

        &:before {
          width: 60%;
        }
      }
    }

    .tabs_reset {
      span {
        width: 100px;
        color: #333333;

        &:before {
          content: '';
          width: 70%;
          height: 3px;
          background: #3E35E7;
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 8px;
          transition: all 0.3s ease-in;
        }

        &:hover {
          &:before {
            width: 70%;
          }
        }
      }
    }

    .tabs_item {
      padding: 0 10px;

      .input_list {
        .el-form-item {
          margin-top: 20px;
          position: relative;

          /deep/ .el-input__inner {
            width: 100%;
            border: none;
            border-radius: 0;
            border-bottom: 2px solid #cccccc;
            padding-left: 0;

            &:focus {
              outline: none;
            }
          }

          &.check_box_area {
            margin-bottom: 20px;
            //overflow: hidden;
            position: relative;

            /deep/ .el-checkbox {
              position: relative;
              height: 30px;
              display: flex;
              align-items: center;

              .el-checkbox__input {
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
              }

              .el-checkbox__label {
                white-space: normal;
                line-height: 1.4rem;
                padding: 0 0 0 20px;
                margin: 0;
              }
            }

            /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #606266;
            }
          }
        }

        .eye_toggle_pwd {
          color: #000000;
          position: absolute;
          cursor: pointer;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;

          .iconfont {
            font-size: 1.2rem;
          }
        }

        .get_code {
          position: absolute;
          cursor: pointer;
          top: 46%;
          transform: translateY(-50%);
          right: 0;
          color: #FFFFFF;
          background: #3e35e7;
          padding: 8px 10px;
          border-radius: 8px;
          border: none;
          font-size: 0.8rem;
          text-align: center;
        }

        .tools {
          text-align: right;
          position: relative;
          font-size: 0.8rem;
          padding-top: 10px;
          margin-bottom: 20px;
          letter-spacing: 0.5px;
          user-select: none;

          :first-child {
            position: absolute;
            left: 0;
          }

          span {
            color: #3E35E7;
            cursor: pointer;
          }
        }
      }

      .btn {
        text-align: center;

        button {
          width: 100%;
          color: #FFFFFF;
          background: #cccccc;
          padding: 10px 40px;
          border-radius: 8px;
          border: none;
          cursor: pointer;
          font-size: 1rem;
          text-align: center;
        }
      }

      .btns {
        display: flex;
      }
    }


  }


}
</style>
